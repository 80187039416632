import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './App';
import './index.css';
// import  admin from "firebase-admin";

// import dotenv from 'dotenv';

// dotenv.config();

const root = ReactDOM.createRoot(document.getElementById('root'));

// var serviceAccount = require("./my-travel-comfort-firebase-adminsdk-scrlu-5814f9699c.json");

// admin.initializeApp({
//   credential: admin.credential.cert(serviceAccount)
// });

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
