import React from 'react';
import Car from '../../assets/images/idf.png';

const Places = () => {
    return (
        <div className='p-6 md:p-8 lg:p-10 bg-primary'>
            <p className='text-white text-2xl md:text-4xl font-bold text-primary text-center mb-2'>
             <span className='text-secondary'>TRANSFERTS AÉROPORTS</span> & <span className='text-secondary'>TRANSFERTS GARES</span>
            </p>
            <p className='text-white text-1xl md:text-4xl  text-primary text-center mb-10'>
             <span className='text-secondary'>24H/24</span> et <span className='text-secondary'>7J/7</span>
            </p>
            <div className='lg:flex justify-between items-center'>
                <div className='lg:w-1/2 lg:flex'>
                    <div className='lg:w-1/2 lg:mr-4'>
                        <p className='text-white font-medium text-[14px] md:text-[16px]'>
                        VTC Aéroport de Roissy CDG<br/>
VTC Aéroport d’Orly<br/>
VTC Aéroport de Paris Le Bourget<br/>
VTC Aéroport de Beauvais Le Tillé<br/>
                            
                        </p>
                    </div>
                    <div className='lg:w-1/2 mt-4 md:mt-0'>
                        <p className='text-white font-medium text-[14px] md:text-[16px]'>
                        VTC Gare de chessy marne la vallée<br/>
                        VTC Gare de L’Est<br/>
                        VTC Gare du Nord<br/>
                        VTC Gare de Lyon   <br/> 
                        VTC Gare de Montparnasse  <br/> 
                        VTC Gare st Lazare  <br/> 
                                       </p>
                    </div>
                </div>

                <div className='lg:w-1/2 flex justify-center mt-4'>
                    <img src={Car} alt="" className='w-[500px]' />
                </div>
            </div>
        </div>
    )
}

export default Places
