import React from 'react';
import Car from '../../assets/images/vtc-paris.png';

const Sec = () => {
    return (
        <div className='p-6 md:p-8 lg:p-10 '>
            <p className=' text-3xl md:text-4xl font-bold text-primary text-center mb-10'>
                Votre <span className='text-lightGreen'>Chauffeur </span>Privé 
            </p>
            <div className='lg:flex justify-between items-center'>
                <div className='lg:w-1/2 lg:flex'>
                    <div className='lg:w-1/2 lg:mr-4'>
                        <p className=' font-medium text-[14px] md:text-[16px]'>
                        Chauffeur MTC Paris ,met à votre disposition ses services de transferts à paris .
                        </p>
                    </div>
                    <div className='lg:w-1/2 mt-4 md:mt-0'>
                        <p className='font-medium text-[14px] md:text-[16px]'>
                        My Travel Comfort, met à votre disposition ses services de transferts à paris . Nous sommes à votre disposition pour toute correspondance. Qu’il s’agisse d’un trajet de la gare vers l’aéroport ou d’une gare à une autre, nous vous garantissons un trajet confortable et serein grace à notre service chauffeur privé paris , VTC paris .                        </p>
                    </div>
                </div>
                <div className='lg:w-1/2 flex justify-center mt-4'>
                    <img src={Car} alt="" className='w-[500px]' />
                </div>
            </div>
        </div>
    )
}

export default Sec
