import React from 'react';
import { FaPhone } from "react-icons/fa";

// Packages :
import { Link } from 'react-router-dom';
// Images :
import Logo from '../assets/images/logo.png';

const Header = () => {
    const handlePhoneClick = () => {
        const phoneNumber = '+33780332323';
        window.location.href = `tel:${phoneNumber}`;
      };
    return (
        <div className='flex justify-between items-center px-4 lg:px-10 py-2 border-b border-lightgray'>
            {/* <p className='text-3xl font-bold italic'>MTC</p> */}
            <img src={Logo} alt="" className='w-20 lg:w-30' />
            <div>
                {/* <Link to="/sign-in" className='text-sm lg:text-base mr-4 text-green font-bold'>Login</Link> */}
                <div onClick={handlePhoneClick} className='text-sm lg:text-base bg-green text-white font-semibold px-6 py-2 rounded-full'>

                <FaPhone />
                </div>
            </div>
        </div>
    )
}

export default Header;