import React from 'react';
import Car from '../../assets/images/parisVtc.png';

const ParisSection = () => {
    return (
        <div className='p-6 md:p-8 lg:p-10 bg-primary'>
            <p className='text-white text-3xl md:text-4xl font-bold text-primary text-center mb-10'>
                Transferts <span className='text-secondary'>VTC </span>à <span className='text-secondary'>Paris</span>
            </p>
            <div className='lg:flex justify-between items-center'>
                <div className='lg:w-1/2 lg:flex'>
                    <div className='lg:w-1/2 lg:mr-4'>
                        <p className='text-white font-medium text-[14px] md:text-[16px]'>
                        Vous arrivez ou quittez paris en avion ? Pensez à effectuer votre réservation VTC paris pour profiter d’un trajet confortable jusqu’à l’aéroport de votre choix. Voyagez sereinement à bord d’une voiture de standing, conduite par un chauffeur privé professionnel et évitez-vous les tracas des transports en commun. Plus de stress : votre chauffeur privé vous récupère où vous voulez, quand vous voulez, s’occupe de vos bagages et vous dépose en un rien de temps à destination. Très utile également lors de votre arrivée à l’aéroport.
                            
                        </p>
                    </div>
                    <div className='lg:w-1/2 mt-4 md:mt-0'>
                        <p className='text-white font-medium text-[14px] md:text-[16px]'>
                        My Travel Comfort vous permet d’oublier les interminables queues aux bornes des bus bondés et autres correspondances mouvementées. Réservez dès maintenant votre VTC paris pour l’un des aéroports de la région : Réservation VTC Aéroport Paris Beauvais - tillé Service Transfert privé Aéroport Paris Charles de gaulles Transfert Aéroport Paris Orly Services de chauffeur privé en VTC Aéroport Paris Le Bourget                        </p>
                    </div>
                </div>

                <div className='lg:w-1/2 flex justify-center mt-4'>
                    <img src={Car} alt="" className='w-[500px]  rounded-2xl' />
                </div>
            </div>
        </div>
    )
}

export default ParisSection
