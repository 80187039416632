import React, { useState,useEffect } from 'react';
import ReserveModal from './reserveModal';
import PlacesAutocomplete from 'react-places-autocomplete';
const LocationInput = ({ value, onChange,key }) => {
    const searchOptions = {
        componentRestrictions: { country: 'fr' }, // Restrict to France
      };
    return (
        <PlacesAutocomplete
        key={key}
        searchOptions= {searchOptions }
            value={value}
            onChange={onChange}
            onSelect={(address) => {
                // Handle selection of the address
                console.log(address);
                onChange(address);
            }}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                    <input
                        {...getInputProps({
                            placeholder: 'Entrez votre adresse',
                            className: 'outline-none text-gray text-lg w-full rounded-sm',
                        })}
                    />
                    <div>
                        {/* {loading && <div>Loading...</div>} */}
                        {suggestions.map((suggestion) => (
                            <div {...getSuggestionItemProps(suggestion)} key={suggestion.placeId}>
                                {suggestion.description}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    );
};

const Form = () => {
    const [startDate, setStartDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [startLocation, setStartLocation] = useState('');
    const [endLocation, setEndLocation] = useState('');
    const [typeTrnasfer, setTypeTransfer] = useState('');
    const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format


   

    // const handleSendFormData = async () => {
    //     try {
    //         console.log('Start Date :', startDate)
    //         console.log('Start Time :', startTime)
    //         console.log('Start Location :', startLocation)
    //         console.log('End Location :', endLocation)
    //         console.log('Type Trnasfer :', typeTrnasfer)
    //     } catch (error) {
    //         console.log('Error sending from data (form.jsx) :', error);
    //     }
    // };

    // const handleCheckInputs = () => {
    //     if (startDate != '' && startTime != '' && startLocation != '' && endLocation != '' && typeTrnasfer != '') {
    //         handleSendFormData();
    //     } else {
    //         setShowMessage(true);
    //         setMessage('You should fill all the inputs !!')
    //     }
    // }


    return (
        <div>
            <div className='w-[90%] mx-auto bg-white rounded-3xl flex lg:flex-row flex-col justify-evenly py-6 px-4 lg:px-0 border border-lightgray shadow-md'>
                {/* Start Date */}
                <div className=''>
                    <p className='font-medium'>Date de départ</p>
                    <input
                        type="date"
                        className='outline-none text-gray text-lg w-full rounded-sm'
                        value={startDate}
                        min={today} // Set minimum date to today

                        onChange={(e) => setStartDate(e.target.value)}
                    />
                </div>

                {/* Start time */}
                <div className='lg:mt-0 mt-4'>
                    <p className='font-medium'>Heure de départ</p>
                    <input
                        type="time"
                        className='outline-none text-gray text-lg w-full rounded-sm'
                        value={startTime}
                        onChange={(e) => setStartTime(e.target.value)}
                    />
                </div>

                {/* Start Location */}
                <div className='lg:mt-0 mt-4'>
                    <p className='font-medium'>Adresse de départ</p>
                    <LocationInput
                        key={"start"}
                        value={startLocation}
                        onChange={(value) => setStartLocation(value)}
                    />
                    {/* <input
                        type="text"
                        className='outline-none text-gray text-lg w-full rounded-sm' placeholder='Agadir, Agadir 80000'
                        value={startLocation}
                        onChange={(e) => setStartLocation(e.target.value)}
                    /> */}
                </div>

                {/* Arrival location */}
                <div className='lg:mt-0 mt-4'>
                    <p className='font-medium'>Adresse d'arrivée</p>
                    <LocationInput
                    key={"arrive"}
                        value={endLocation}
                        onChange={(value) => setEndLocation(value)}
                    />
                   
                </div>

                {/* Type of transfer */}
                <div className='lg:mt-0 mt-4'>
                    <p className='font-medium'>Type de transfert</p>
                    <select
                        className='border-none outline-none text-gray text-lg w-full rounded-sm'
                        value={typeTrnasfer}
                        onChange={(e) => setTypeTransfer(e.target.value)}
                    >
                        <option selected="true" value="opt1">Aller</option>
                    </select>
                </div>
            </div>

            {/* Button */}
            <div className='flex justify-center mt-6'>
                {/* <button className='text-primary bg-secondary px-14 py-4 rounded-full font-bold'>Reserve maintenant</button> */}
                <ReserveModal
                    startDate={startDate}
                    startTime={startTime}
                    startLocation={startLocation}
                    endLocation={endLocation}
                    typeTrnasfer={typeTrnasfer}
                />
            </div>
        </div>
    )
}

export default Form;