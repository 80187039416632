import React, { useEffect, useState } from 'react';
import  {db} from "../../firebase";
import {  collection,  addDoc } from 'firebase/firestore';
import Swal from 'sweetalert2';
// import { Spinner } from "./spinner";



// Packages :
import { Box, Modal, Rating } from '@mui/material';
import ModernInput from '../myinput';


// Icons :
import { GrFormClose } from "react-icons/gr";
import { MdOutlineLocationOn } from 'react-icons/md';
import { CiLocationArrow1 } from 'react-icons/ci';
import { AiOutlineSend } from 'react-icons/ai';
// Images :
import Car1 from '../../assets/images/ecocar.jpeg'
import Car2 from '../../assets/images/berlincar.jpeg'
import Car3 from '../../assets/images/vancar.jpeg'





const ReserveModal = ({ startDate, startTime, startLocation, endLocation, typeTrnasfer }) => {
    const [open, setOpen] = useState(false);
    const [name, setName] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [fakeData,setFakeData ] = useState([
        { id: 1, category: 'GAMME ECO', desc: 'Trajets écoresponsables sans sacrifier le confort.',price:0,prix20km:40, prix1km:2,carImg: Car1 },
        { id: 2, category: 'GAMME BERLINE', desc: ' Élégance et performance fusionnent dans une conduite raffinée.', price: 0,prix24km:60, prix1km:2.5, carImg: Car2 },
        { id: 3, category: 'GAMME VAN', desc: 'Confort spacieux pour les groupes, un mélange parfait de style et de capacité.', price: 0,prix23km:70, prix1km:3, carImg: Car3 },
        // { id: 4, driverName: 'Jeff M. Nichols', distance: '2.1 Km', time: '8 min', price: 30, driverRate: 4.5, carImg: Car4 },
        // { id: 5, driverName: 'John B. Miller', distance: '1 Km', time: '3 min', price: 12, driverRate: 3, carImg: Car5 },
    ]);
    

    const handleOpen = () => {
        setOpen(true)
        // handleCheckInputs();
    };

    const handleClose = () => setOpen(false);

    const currentWdith = window.innerWidth;
    const [width, setWidth] = useState('80%');
    const [padding, setPadding] = useState(4);
    const [distance, setDistance] = useState(0);
    const [nameError, setNameError] = useState(false);
    const [loading,setLoading]=useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState(false);


    useEffect(() => {
        if (currentWdith <= 770) {
            setWidth('90%');
            setPadding(2);
        }
    }, [currentWdith]);


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width,
        height: 'auto',
        maxHeight: '90vh',
        borderRadius: '8px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: padding,
        overflowY: 'scroll'
    };

    

    // ============================================================
    const handleSendFormData = () => {
        // try {
        console.log('Start Date :', startDate);
        console.log('Start Time :', startTime);
        console.log('Start Location :', startLocation);
        console.log('End Location :', endLocation);
        console.log('Type Trnasfer :', typeTrnasfer);
        // } catch (error) {
        //     console.log('Error sending from data (form.jsx) :', error);
        // }
    };
    const calculateDistance = () => {
        console.log(startLocation," | ",endLocation);
    const service = new window.google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [startLocation],
        destinations: [endLocation],
        travelMode: 'DRIVING',
        unitSystem: window.google.maps.UnitSystem.METRIC,
      },
      (response, status) => {
        if (status === 'OK' && response.rows[0].elements[0].status === 'OK') {
            const distanceText = response.rows[0].elements[0].distance.text;
            const distanceValue = parseInt(distanceText.match(/\d+/)[0], 10);
            console.log(distanceValue);
           const updatedFakeData = fakeData.map((e) => {
                    let updatedPrice;
                    //Eco
                    if (distanceValue > 0 && distanceValue <= 20 && e.id===1) {
                        updatedPrice = e.prix20km;
                    } else if (distanceValue > 20 && e.id===1) {
                        updatedPrice = e.prix20km + (distanceValue-20)*e.prix1km;
                    }
                    //Berline
                    if (distanceValue > 0 && distanceValue <= 24 && e.id===2) {
                        updatedPrice = e.prix24km;
                    } else if (distanceValue > 24 && e.id===2) {
                        updatedPrice = e.prix24km + (distanceValue-24)*e.prix1km;
                    }
                    //Van
                    if (distanceValue > 0 && distanceValue <= 23 && e.id===3) {
                        updatedPrice = e.prix23km;
                    } else if (distanceValue > 23 && e.id===3) {
                        updatedPrice = e.prix23km + (distanceValue-23)*e.prix1km;
                    }


                    return { ...e, price: updatedPrice };
                });

                setFakeData(updatedFakeData);

        

            setFakeData(updatedFakeData);

            setDistance(distanceValue);
        } else {
          console.error('Error calculating distance:', status);
        }
      }
    );
}
const handleSendWhatsAppMessage = async (index) => {
        setNameError(false);
        setPhoneNumberError(false);

        // Validation logic
        if (!name) {
            setNameError(true);
        }

        if (!phoneNumber) {
            setPhoneNumberError(true);
        }
        // If any validation error, stop here
        if (!name || !phoneNumber) {
            return;
        }
        const pickupDateTime = new Date(startDate + ' ' + startTime);

setLoading(true);
        //send to firebase
        const courseCollection = collection(db, 'courses');
        const newCourseDoc =  await addDoc(courseCollection, {
            name: name,
            phone: phoneNumber,
            from: startLocation,
            to: endLocation,
            createdDate: Date.now(),
            pickupDateTime: pickupDateTime,
            price: fakeData[index]?.price,
            distance: distance,
            category:fakeData[index]?.category,
            valide:false
        });
        if (newCourseDoc) {
            // Display SweetAlert success message in French
            Swal.fire({
                icon: 'success',
                title: 'Succès',
                text: 'Votre réservation a bien été prise en compte. Vous serez contacté par un membre de l’équipe dans les plus brefs délais'
            });
        } else {
            // Display SweetAlert error message in French
            Swal.fire({
                icon: 'error',
                title: 'Erreur',
                text: 'Échec de l\'ajout du course.'
            });
        }
        setOpen(false);
        setLoading(false);




    // Assuming you have the necessary information in state variables
    // const message = `Reservation Details%0ADate: ${startDate}%0AHeure: ${startTime}%0ANom Client: ${name}%0ANum Client: ${phoneNumber}%0APec: ${startLocation}%0ADépose: ${endLocation}%0ADistance: ${distance} km%0APrix: ${fakeData[index]?.price} EUR%0ACategorie: ${fakeData[index]?.category}`;

    // window.open(`https://wa.me/+33780332323?text=${message}`, '_blank');
};
    const handleCheckInputs = () => {
        if (startDate != '' && startTime != '' && startLocation != '' && endLocation != '') {
            // handleSendFormData();
            console.log('Start Date :', startDate);
            console.log('Start Time :', startTime);
            console.log('Start Location :', startLocation);
            console.log('End Location :', endLocation);
            console.log('Type Trnasfer :', typeTrnasfer);
            calculateDistance();
            handleOpen();
            return;
        } else {
            setShowMessage(true);
            setMessage('You should fill all the inputs !!')
        }
    }

    return (
        <div>
            {loading ?
            <div className=" w-[82vw] h-full flex items-center justify-center">
             <div className={`border-8 border-dashed rounded-full animate-spin border-termine w-[10vw] h-[10vw] lg:w-[2vw] lg:h-[2vw]`}></div>

            </div>
            :
        
        
        <div>
            {
                showMessage && (
                    <div className='mb-4'>
                        <p className='text-[red] font-medium text-sm text-center'>{message}</p>
                    </div>
                )
            }

            <button onClick={handleCheckInputs} style={{ height: "55px" }} className='text-sm md:text-base text-primary bg-secondary px-10 md:px-14 py-2 md:py-4 rounded-full font-bold'>
                Réservez maintenant
            </button>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {/* <ModernInput placeholder="Your Placeholder Text" /> */}


                <Box sx={style}>
                    <div className='flex justify-between items-center   mb-4'>
                        <p className='text-1xl font-bold'>vos informations</p>
                        <GrFormClose className='text-2xl cursor-pointer' onClick={handleClose} />
                    </div>
                    <div className='lg:w-[50%] w-[90%] mx-auto mb-2'>
                        {/* <p className='font-medium mb-2'>Votre nom</p> */}
                        <input
                            type="name"
                            placeholder='Votre nom'
                            className={`border ${nameError ? 'border-[red]' : 'border-lightgray'} rounded-md p-2 pl-4 w-full outline-none`}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>

                    <div className='lg:w-[50%] w-[90%] mx-auto mb-6'>
                        {/* <p className='font-medium mb-2'>Votre numéro de téléphone</p> */}
                        <input
                            type="tel"
                            pattern="[0-9]*"  // Use a pattern to allow only numeric characters
                            placeholder='Votre numéro de téléphone'
                            className={`border ${phoneNumberError ? 'border-[red]' : 'border-lightgray'} rounded-md p-2 pl-4 w-full outline-none`}
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            required
                        />
                    </div>

                    <div className='flex justify-between items-center   mb-4'>
                        <p className='text-1xl font-bold'>Choisissez votre catégorie</p>
                    </div>

                    {/* Cards */}
                    <div className='flex flex-wrap justify-between items-center'>
                        {/* Card 01 */}

                        {
                            fakeData?.map((data, index) => (
                                <div key={index} className="lg:w-1/2">
                                    <div className='m-2 border border-gray rounded-xl mx-2'>
                                        <div className='flex justify-between items-center border-b border-gray p-4'>
                                            <div className='w-2/3'>
                                                <p className='text-1xl font-semibold'>{data?.category}</p>
                                                <p>{data?.desc}</p>
                                                <p className='flex items-center mb-4'>
                                                    {/* <MdOutlineLocationOn className='text-xl' /> */}
                                                    {/* <p>{data?.distance} ({data?.time})</p> */}
                                                </p>
                                                {/* <Rating name="read-only" value={data?.driverRate} precision={0.5} readOnly /> */}
                                            </div>
                                            <div className='w-1/2 flex flex-col justify-end'>
                                                <img src={data?.carImg} alt="" className='w-72' />
                                            </div>
                                        </div>
                                        <div className='flex justify-between items-center p-4'>
                                            <p className='font-extrabold text-2xl text-green'>{data?.price} EUR</p>
                                            <button
    onClick={() => handleSendWhatsAppMessage(index)}
    className='bg-green p-2 px-10 rounded-md font-medium text-white flex items-center'>
                                                <AiOutlineSend className='text-lg mr-2 font-bold' />
                                                Réservez
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                </Box>
            </Modal>
        </div>}
        </div>
    )
}

export default ReserveModal;