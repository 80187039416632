import React, { useEffect, useState } from 'react';
import Form from './form';

const Content = () => {
    const currentWidth = window.innerWidth;
    const [background, setBackground] = useState('background-image');
    const [fontSize, setFontSize] = useState('text-4xl');
    const [marginTop, setMarginTop] = useState('mt-[22vh]');

    useEffect(() => {
        if (currentWidth <= 770) {
            setBackground('bg-primary');
            setFontSize('text-3xl');
            setMarginTop('mt-5'); // Adjust the margin-top as needed
        }
    }, [currentWidth]);

    return (
        <div className={`min-h-[95vh] overflow-hidden ${background}`}>
            <div className={`lg:w-[60%] px-5 lg:ml-14 ${marginTop}`}>
                <p className={`${fontSize} font-extrabold text-white`}>
                Le choix idéal pour des trajets<span className='text-secondary'> confortables</span>
                </p>
                {/* <p className={`text-base md:text-lg font-medium text-white mt-3 lg:mt-6 ${fontSize}`}>
                    Où que vous soyez et quand vous en avez besoin, nous avons le véhicule parfait pour vous à un prix abordable.
                </p> */}
            </div>
            <div className='absolute bottom-6 w-full'>
                <Form />
            </div>
        </div>
    );
}

export default Content;
