
import React, { useState, useEffect } from 'react';

import Header from '../components/header';
import Content from '../components/homeComponents/content';
import Section1 from '../components/homeComponents/section1';
import ParisSection from '../components/homeComponents/parissection';
import Sec from '../components/homeComponents/sec';
import Places from '../components/homeComponents/places';

import NosServices from '../components/homeComponents/nosServices';
import AboutUs from '../components/homeComponents/aboutUs';
import Testimonials from '../components/homeComponents/testimonials';
import Footer from '../components/footer';


const Home = () => {
    const [scriptLoaded, setScriptLoaded] = useState(false);

    useEffect(() => {
        const script = document.createElement('script');
        // script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCO_nJn1XlNOpRlnOeTCcsdIFcXL7QrgNg&libraries=places`;
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAn9E3m5Enkiv4PJYDJtwssixmTHKfPA-s&libraries=places`;

        script.async = true;
        script.defer = true;
        script.addEventListener('load', () => setScriptLoaded(true));

        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);
    if (!scriptLoaded)
        return (
            <div>
                Loading...
            </div>
        )
    else
        return (
            <div>
                <Header />
                <Content />
                <Sec/>
                <ParisSection/>
                <Section1 />
                <NosServices />
                <AboutUs />
                {/* <Testimonials /> */}
                <Places/>
                <Footer />
            </div>
        )
}

export default Home;